// React
import { useEffect, useState } from "react";
// Trads
import { trads as allTrads } from "../../datas/trads";
// CSS
import "./landingPage.css";
// GIFs
import gifLandingPage from "../../style/assets/playGif.gif";
// Images
import bgLandingPage from "../../style/assets/background.jpg";
import React from "react";
import { experiences } from "../../datas/experiences";
// Assets
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import prLogo from "../../style/assets/intro/prLogo.png";
import jepLogo from "../../style/assets/intro/jepLogo.png";
import { GetTrad } from "../Partials/GetTrad";

export const LandingPage = (props: any) => {
	useEffect(() => {
		let homeElem = document.getElementsByClassName("home");
		if (homeElem) {
			for (let elem of homeElem) {
				(elem as HTMLElement).style.pointerEvents = "all";
			}
		}

		const scrollManagment = (e?: Event) => {
			let elem = document.getElementById("landingPage-container");
			let elemOpacity = document.getElementById("landingPage-textContainer");
			if (elem && elemOpacity) {
				let { scrollHeight, scrollTop, clientHeight } = elem;

				let max = scrollHeight - clientHeight;
				let percent = ((scrollHeight - scrollTop - clientHeight) * 100) / max;

				if (percent < 0) {
					percent = 0;
				}

				elemOpacity.style.setProperty("--maskHeight", 100 - percent + "%");

				let elemArrow = document.getElementById("landingPage-textScroll");
				if (elemArrow) {
					if (scrollHeight - scrollTop - 15 > clientHeight) {
						elemArrow.style.opacity = "1";
					} else {
						elemArrow.style.opacity = "0";
					}
				}
			}
		};

		scrollManagment();

		document.getElementById("landingPage-container")?.addEventListener("scroll", (e: Event) => scrollManagment(e));
	}, []);

	// const goNext = () => {
	// 	console.log('↳ Start playing');

	// 	if (props.next) {
	// 		props.next();
	// 	}

	// 	let languageElem = document.getElementById('landingPage');
	// 	if (languageElem) {
	// 		languageElem.style.opacity = '0';

	// 		let homeElem = document.getElementsByClassName('home');
	// 		if (homeElem) {
	// 			for (let elem of homeElem) {
	// 				(elem as HTMLElement).style.pointerEvents = 'none';
	// 			}
	// 		}
	// 	}
	// };

	// const shareData = {
	// 	title: "Aux Origines de l'Elysée, 1722",
	// 	url: 'https://elysee.timescope.com/',
	// };

	const [experience, setExperience] = useState<any>();
	const [otherExperience, setOtherExperience] = useState<any>();

	useEffect(() => {
		setExperience(experiences.find((e: any) => e.id === props.landingPage));
		setOtherExperience(experiences.find((e: any) => e.id !== props.landingPage));
	}, [props.landingPage]);

	return (
		<React.Fragment>
			{experience && (
				<div className='landingPage' style={{ backgroundImage: `url(${experience.affiche.blur})` }}>
					<div
						style={{
							position: "absolute",
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.6)",
						}}
					></div>
					<div className='landingPage-overflowed'>
						<div className='landingPage-band' onClick={() => document.location.reload()}>
							<div className='landingPage-band-cross'></div>
						</div>
						<div
							className='landingPage-title'
							style={{ backgroundImage: `url(${experience.title.img})` }}
						></div>
						<div className='landingPage-content'>
							{experience.endPage.map((c: any) => {
								switch (c.type) {
									case "title":
										return (
											<div className='landingPage-content-title'>
												<GetTrad value={c.value} />
											</div>
										);
									case "text":
										return (
											<div className='landingPage-content-text'>
												<GetTrad value={c.value} />
											</div>
										);
									case "share":
										return (
											<div
												className='landingPage-share'
												onClick={() => {
													navigator.share({
														url: "/" + experience.id,
														text: experience.share.header,
														title: experience.share.header,
													});
												}}
											>
												<div className='landingPage-share-icon'></div>
												<div className='landingPage-share-text'>partager</div>
											</div>
										);
								}
							})}
						</div>
						<div className='landingPage-bloc'>
							<div className='intro-bloc-header'>
								<GetTrad value={otherExperience.movie.title} />
							</div>
							<div
								className='intro-bloc-videoTrailer btnStart'
								onClick={() => {
									props.next(otherExperience.id);
									props.setLandingPage(null)
								}}
								style={{ backgroundImage: `url(${otherExperience.movie.vignette})` }}
							>
								<div className='intro-bloc-videoTrailer-playIcon'></div>
								{/* {otherExperience.movie.new && (
									<div className='intro-bloc-videoTrailer-newIcon'>
										<div className='intro-bloc-videoTrailer-newIconText'>Nouveau</div>
									</div>
								)} */}
							</div>
							<div className='intro-bloc-infos' onClick={() => {
								props.displayInfoPage(otherExperience.id);
								props.setLandingPage(null)
							}}>
								<div className='intro-bloc-infos-more'>En savoir plus</div>
								<div className='intro-bloc-infos-timer'>{otherExperience.movie.duration}</div>
							</div>
						</div>
						<div className='intro-logos'>
							<div
								className='intro-logos-logo'
								style={{ backgroundImage: `url(${timescopeLogo})` }}
							></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${prLogo})` }}></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${jepLogo})` }}></div>
						</div>
					</div>
				</div>
			)}

			{/* <div id='intro-fade-bottom' className='intro-fade-bottom'></div> */}
		</React.Fragment>
	);
};
