// React
import React, { UIEvent } from "react";
// Components
import { Menu } from "./Menu";
// Styles
import "./intro.css";
// Assets
import vignetteEATS from "../../style/assets/intro/vignetteEATS.jpg";
import vignetteAOE from "../../style/assets/intro/vignetteAOE.jpg";
import timescopeLogo from "../../style/assets/intro/timescopeLogo.png";
import prLogo from "../../style/assets/intro/prLogo.png";
import jepLogo from "../../style/assets/intro/jepLogo.png";

export const Intro = (props: any) => {
	return (
		<React.Fragment>
			<div className='intro'>
				<div className='intro-header'>
					<div className='intro-header-logo'></div>
				</div>
				<div className='intro-overflowedContainer'>
					<div className='intro-overflowed'>
						<div className='intro-explanations'>
							<div className='intro-explanations-title'>{"Voyagez dans le temps !".toUpperCase()}</div>
							<div className='intro-explanations-text'>
								<div className='intro-explanations-textLine'>
									{
										"À l'occasion des Journées Européennes du Patrimoine 2024, plongez dans l'histoire du Palais de l'Élysée !"
									}
								</div>
							</div>
							<div className='intro-explanations-text'>
								<div className='intro-explanations-textLine'>
									{
										"Deux expériences de réalité virtuelle vous sont proposées. Êtes-vous prêt pour un voyage dans le temps ?"
									}
								</div>
							</div>
						</div>
						<div className='intro-bloc'>
							<div className='intro-bloc-header'>L'Élysée à travers les siècles</div>
							<div
								className='intro-bloc-videoTrailer btnStart'
								onClick={() => {
									props.next("eats");
								}}
								style={{ backgroundImage: `url(${vignetteEATS})` }}
							>
								<div className='intro-bloc-videoTrailer-playIcon'></div>
								{/* <div className='intro-bloc-videoTrailer-newIcon'>
									<div className='intro-bloc-videoTrailer-newIconText'>Nouveau</div>
								</div> */}
							</div>
							<div
								className='intro-bloc-infos'
								onClick={() => {
									props.infos("eats");
								}}
							>
								<div className='intro-bloc-infos-more'>En savoir plus</div>
								<div className='intro-bloc-infos-timer'>4:38</div>
							</div>
						</div>
						<div className='intro-bloc'>
							<div className='intro-bloc-header'>Aux Origines de l'Élysée</div>
							<div
								className='intro-bloc-videoTrailer btnStart'
								onClick={() => {
									props.next("aoe");
								}}
								style={{ backgroundImage: `url(${vignetteAOE})` }}
							>
								<div className='intro-bloc-videoTrailer-playIcon'></div>
							</div>
							<div
								className='intro-bloc-infos'
								onClick={() => {
									props.infos("aoe");
								}}
							>
								<div className='intro-bloc-infos-more'>En savoir plus</div>
								<div className='intro-bloc-infos-timer'>2:52</div>
							</div>
						</div>
						<div className='intro-logos'>
							<div
								className='intro-logos-logo'
								style={{ backgroundImage: `url(${timescopeLogo})` }}
							></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${prLogo})` }}></div>
							<div className='intro-logos-logo' style={{ backgroundImage: `url(${jepLogo})` }}></div>
						</div>
					</div>
					<div id='intro-fade-bottom' className='intro-fade-bottom'></div>
				</div>
			</div>
		</React.Fragment>
	);
};
